import { render, staticRenderFns } from "./TotpDisable.vue?vue&type=template&id=6641a085&"
import script from "./TotpDisable.vue?vue&type=script&lang=ts&"
export * from "./TotpDisable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./TotpDisable.en.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fcomponents%2Faccount%2FTotpDisable.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports