



































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      error: null,
      isSubmitting: false,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/Get',
    }),
  },
  methods: {
    submit() {
      this.showModal = true;
      this.isSubmitting = true;
    },
    async handleAuthenticationToken(token: any) {
      this.showModal = false;

      await this.$store.dispatch('account/mfa/DisableTotp', {
        TOKEN: token.id.concat(token.secret),
      }).catch((e) => { this.error = e; });

      this.isSubmitting = false;
    },
  },
});
