










import Vue from 'vue';
import TotpComponent from '@/components/account/Totp.vue';

export default Vue.extend({
  components: {
    TotpComponent,
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
