


























































































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      error: null,
      loading: false,
      copied: null as string|null,
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/Get',
      remainingCodes: 'account/mfa/TotpRemainingCodes',
      recoverySecrets: 'account/mfa/TotpRecoverySecrets',
    }),
    allSecrets(): string {
      let str = '';

      this.recoverySecrets.forEach(({ secret }: { secret: string }) => {
        str = str.concat(secret, ',\n');
      });

      return str;
    },
  },
  methods: {
    copy(secret: string) {
      navigator.clipboard.writeText(secret);
      this.copied = secret;
    },
    submit() {
      this.loading = true;
    },
    async handleAuthenticationToken(token: any) {
      await this.$store.dispatch('account/mfa/GenerateTotpRecoverySecrets', {
        TOKEN: token.id.concat(token.secret),
      }).catch((e) => { this.error = e; });

      this.loading = false;

      this.$store.dispatch('account/mfa/GetTotpStatus').catch((e) => { this.error = e; });
    },
  },
});
