



























import Vue from 'vue';
import { mapGetters } from 'vuex';
import TotpEnable from '@/components/account/TotpEnable.vue';
import TotpDisable from '@/components/account/TotpDisable.vue';
import TotpGenerateRecoverySecrets from '@/components/account/TotpGenerateRecoverySecrets.vue';

export default Vue.extend({
  components: {
    TotpDisable,
    TotpEnable,
    TotpGenerateRecoverySecrets,
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      status: 'account/mfa/TotpStatus',
      loaded: 'account/mfa/TotpStatusLoaded',
      remainingCodes: 'account/mfa/TotpRemainingCodes',
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await this.$store.dispatch('account/mfa/GetTotpStatus').catch((e) => { this.error = e; });
    },
  },
});
