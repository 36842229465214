













































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      error: null,
      form: {
        code: '',
        confirm: false,
      },
      isLoading: false,
      isSubmitting: false,
      recoverySecret: null,
      showForm: false,
      showModal: false,
      showSecret: false,
      validation: {
        code: null,
        confirm: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/Get',
      secret: 'account/mfa/TotpSecret',
      loaded: 'account/mfa/TotpSecretLoaded',
      blobUrl: 'account/mfa/TotpSecretBlobUrl',
      qr: 'account/mfa/TotpSecretQr',
      src: 'account/mfa/TotpSecretSrc',
      uri: 'account/mfa/TotpSecretUri',
    }),
  },
  methods: {
    async prepare(): Promise<void> {
      this.isLoading = true;
      await this.$store.dispatch('account/mfa/PrepareTotp')
        .catch((e) => { this.error = e; });
      this.isLoading = false;
    },
    submit(): void {
      if (this.validate()) return;

      this.showModal = true;
      this.isSubmitting = true;
    },
    async handleAuthenticationToken(attempt: any): Promise<void> {
      this.showModal = false;

      const data = await this.$store.dispatch('account/mfa/EnableTotp', {
        TOKEN: attempt.id.concat(attempt.secret),
        form: {
          otp: this.form.code,
        },
      })
        .catch((e) => { this.error = e; });

      this.isSubmitting = false;

      if (!data) return;
      this.recoverySecret = data.data.secret;
    },
    validate(validation: any = {}): boolean {
      const { form: F } = this;
      const v = { ...validation };

      if (!F.code) {
        v.code = 'notOptional';
      }

      if (!F.confirm) {
        v.confirm = 'trueVal';
      }

      this.validation = v;
      // Check if all keys of v are null, will return false(=!true)
      return !Object.keys(v).every((k) => v[k] === null);
    },
    async goBack(): Promise<void> {
      await this.$store.dispatch('account/mfa/GetTotpStatus')
        .catch((e) => { this.error = e; });
    },
  },
});
